import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { TimeoutWrapper } from 'docs/helpers/TimeoutWrapper';
import { IconGroup } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/IconGroup" title="IconGroup" mdxType="PageTitle" />
    <h2 {...{
      "id": "size-small"
    }}>{`Size small`}</h2>
    <Playground __position={1} __code={'<IconGroup scale=\"small\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup scale="small" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "size-medium"
    }}>{`Size medium`}</h2>
    <Playground __position={2} __code={'<IconGroup scale=\"medium\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup scale="medium" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "size-large"
    }}>{`Size large`}</h2>
    <Playground __position={3} __code={'<IconGroup scale=\"large\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup scale="large" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "size-xlarge"
    }}>{`Size xlarge`}</h2>
    <Playground __position={4} __code={'<IconGroup scale=\"xlarge\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup scale="xlarge" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "size-2xlarge"
    }}>{`Size 2xlarge`}</h2>
    <Playground __position={5} __code={'<IconGroup scale=\"2xlarge\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup scale="2xlarge" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "size-3xlarge"
    }}>{`Size 3xlarge`}</h2>
    <Playground __position={6} __code={'<IconGroup scale=\"3xlarge\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup scale="3xlarge" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "sized-group-by-pixel"
    }}>{`Sized group by pixel`}</h2>
    <Playground __position={7} __code={'<IconGroup size={18}>\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup size={18} mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "sized-group-by-em"
    }}>{`Sized group by em`}</h2>
    <Playground __position={8} __code={'<IconGroup size=\"1em\">\n  <Rhythm m={2}>\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n    <TimesIcon />\n  </Rhythm>\n</IconGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <IconGroup size="1em" mdxType="IconGroup">
    <Rhythm m={2} mdxType="Rhythm">
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
      <TimesIcon mdxType="TimesIcon" />
    </Rhythm>
  </IconGroup>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "scaled-group-by-state"
    }}>{`Scaled group by state`}</h3>
    <Playground __position={9} __code={'<StateWrapper\n  initialState={0}\n  setStateFromPrevious={previous => previous + 1}\n>\n  {({ state: count, setState: setCount }) => {\n    const sizes = [\'small\', \'medium\', \'large\', \'xlarge\', \'2xlarge\', \'2xlarge\']\n    return (\n      <TimeoutWrapper infinite callback={setCount} milliseconds={500}>\n        <IconGroup scale={sizes[count % sizes.length]}>\n          <Rhythm\n            m={2}\n            style={{ height: \'40px\', transition: \'all 500ms linear\' }}\n          >\n            <TimesIcon />\n            <TimesIcon />\n            <TimesIcon />\n            <TimesIcon />\n            <TimesIcon />\n            <TimesIcon />\n          </Rhythm>\n        </IconGroup>\n      </TimeoutWrapper>\n    )\n  }}\n</StateWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      Rhythm,
      PageTitle,
      StateWrapper,
      TimeoutWrapper,
      IconGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateWrapper initialState={0} setStateFromPrevious={previous => previous + 1} mdxType="StateWrapper">
    {({
          state: count,
          setState: setCount
        }) => {
          const sizes = ['small', 'medium', 'large', 'xlarge', '2xlarge', '2xlarge'];
          return <TimeoutWrapper infinite callback={setCount} milliseconds={500} mdxType="TimeoutWrapper">
          <IconGroup scale={sizes[count % sizes.length]} mdxType="IconGroup">
            <Rhythm m={2} style={{
                height: '40px',
                transition: 'all 500ms linear'
              }} mdxType="Rhythm">
              <TimesIcon mdxType="TimesIcon" />
              <TimesIcon mdxType="TimesIcon" />
              <TimesIcon mdxType="TimesIcon" />
              <TimesIcon mdxType="TimesIcon" />
              <TimesIcon mdxType="TimesIcon" />
              <TimesIcon mdxType="TimesIcon" />
            </Rhythm>
          </IconGroup>
        </TimeoutWrapper>;
        }}
  </StateWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={IconGroup} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      